<template>
  <div class="container">
    <CardsComponent />
  </div>
</template>

<script>
import Slidebar from "@/components/Slidebar.vue";
import CardsComponent from "@/components/CardsComponent.vue";
export default {
  components: {
    Slidebar,
    CardsComponent,   
  },

  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted(){

  },
};
</script>
<style  scoped>
.container {
  width: 98%;
}
</style>
